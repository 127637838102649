var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/admin/diet-program"
    }
  }, [_vm._v(" Manajemen Program ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Edit" : "Tambah") + " Diet Program ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px",
      "mb": "50px"
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Edit" : "Tambah") + " Diet Program ")]), _c('form', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "max-w": "590px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align": "baseline",
      "mb": "25px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "min-w": "180px",
      "w": "180px",
      "mr": "40px",
      "is-invalid": true
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Gambar Program ")]), _c('label', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        bg: 'white',
        cursor: 'pointer',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '6px',
        w: '180px',
        h: '180px',
        d: 'flex',
        p: '0',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      },
      expression: "{\n            bg: 'white',\n            cursor: 'pointer',\n            border: '1px solid #C4C4C4',\n            boxSizing: 'border-box',\n            borderRadius: '6px',\n            w: '180px',\n            h: '180px',\n            d: 'flex',\n            p: '0',\n            alignItems: 'center',\n            justifyContent: 'center',\n            overflow: 'hidden',\n          }"
    }],
    attrs: {
      "for": "image-program"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.getPreviewImage(_vm.photoUrl),
      "w": !_vm.getPreviewImage(_vm.photoUrl).includes('icon-photo') ? '100%' : '94px',
      "alt": "img program",
      "mx": "auto",
      "border-radius": "6px"
    }
  })], 1), _c('c-input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "image-program",
      "type": "file",
      "accept": ".jpg, .jpeg, .png",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Deskripsi Konten",
      "height": "62px"
    },
    on: {
      "change": _vm.onFileChange
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "align-self": "center",
      "w": "100%",
      "is-invalid": true
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "gray.900",
      "font-weigh": "normal",
      "font-family": "Roboto"
    }
  }, [_vm._v(" File maksimal 3 MB ")])], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.name === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Program ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Nama Program",
      "height": "62px"
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.overview === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Overview ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.overview,
      expression: "overview"
    }, {
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n          border: '1px solid #C4C4C4',\n          padding: '18px',\n          width: '100%',\n          borderRadius: '6px',\n          '&:focus, &:focus-visible': {\n            outline: 'unset',\n          },\n        }"
    }],
    attrs: {
      "rows": "3",
      "placeholder": "Masukkan Overview"
    },
    domProps: {
      "value": _vm.overview
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.overview = $event.target.value;
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.description === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Deskripsi ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.description,
      expression: "description"
    }, {
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n          border: '1px solid #C4C4C4',\n          padding: '18px',\n          width: '100%',\n          borderRadius: '6px',\n          '&:focus, &:focus-visible': {\n            outline: 'unset',\n          },\n        }"
    }],
    attrs: {
      "rows": "3",
      "placeholder": "Masukkan Deskripsi"
    },
    domProps: {
      "value": _vm.description
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.description = $event.target.value;
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.suitableFor === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Program Ini Cocok ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.suitableFor,
      expression: "suitableFor"
    }, {
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n          border: '1px solid #C4C4C4',\n          padding: '18px',\n          width: '100%',\n          borderRadius: '6px',\n          '&:focus, &:focus-visible': {\n            outline: 'unset',\n          },\n        }"
    }],
    attrs: {
      "rows": "3",
      "placeholder": "Masukkan Program Ini Cocok"
    },
    domProps: {
      "value": _vm.suitableFor
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.suitableFor = $event.target.value;
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.programTarget === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Target Program ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.programTarget,
      expression: "programTarget"
    }, {
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n          border: '1px solid #C4C4C4',\n          padding: '18px',\n          width: '100%',\n          borderRadius: '6px',\n          '&:focus, &:focus-visible': {\n            outline: 'unset',\n          },\n        }"
    }],
    attrs: {
      "rows": "3",
      "placeholder": "Masukkan Target Program"
    },
    domProps: {
      "value": _vm.programTarget
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.programTarget = $event.target.value;
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mb": "25px",
      "is-required": "",
      "is-invalid": _vm.serviceFacility === ''
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Fasilitas Program ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.serviceFacility,
      expression: "serviceFacility"
    }, {
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '18px',
        width: '100%',
        borderRadius: '6px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n          border: '1px solid #C4C4C4',\n          padding: '18px',\n          width: '100%',\n          borderRadius: '6px',\n          '&:focus, &:focus-visible': {\n            outline: 'unset',\n          },\n        }"
    }],
    attrs: {
      "rows": "3",
      "placeholder": "Masukkan Fasilitas Program"
    },
    domProps: {
      "value": _vm.serviceFacility
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.serviceFacility = $event.target.value;
      }
    }
  })], 1), _c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px",
      "color": "primary.400",
      "mb": "25px"
    }
  }, [_vm._v(" Layanan Program ")]), _vm._l(_vm.productServices, function (item, index) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.status,
        expression: "!item.status"
      }],
      key: 'service' + index,
      attrs: {
        "position": "relative",
        "border-top": index > 0 ? 'thin solid #f2f2f2' : '',
        "mt": index > 0 ? '25px' : '',
        "pt": index > 0 ? '25px' : ''
      }
    }, [_c('c-box', {
      attrs: {
        "position": "absolute",
        "top": "40px",
        "right": "-45px"
      }
    }, [_c('c-button', {
      attrs: {
        "ml": "18px",
        "min-width": "auto",
        "variant-color": "red",
        "variant": "link"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeletService(item, index);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-trash-line.svg'),
        "alt": "empty"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "is-invalid": item.programsService === '',
        "is-required": "",
        "mb": "25px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Nama Layanan ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "text",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Nama Layanan",
        "height": "62px"
      },
      model: {
        value: item.programsService,
        callback: function callback($$v) {
          _vm.$set(item, "programsService", $$v);
        },
        expression: "item.programsService"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "is-invalid": item.duration === '',
        "is-required": "",
        "mb": "25px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Durasi Layanan ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input', {
      attrs: {
        "type": "number",
        "min": "0",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Durasi Layanan",
        "height": "62px"
      },
      model: {
        value: item.duration,
        callback: function callback($$v) {
          _vm.$set(item, "duration", $$v);
        },
        expression: "item.duration"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "is-invalid": item.price === '',
        "is-required": "",
        "mb": "25px"
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Harga ")]), _c('c-input-group', {
      attrs: {
        "size": "md"
      }
    }, [_c('c-input-left-addon', {
      attrs: {
        "height": "62px"
      }
    }, [_vm._v(" Rp ")]), _c('c-input', {
      attrs: {
        "type": "number",
        "min": "0",
        "error-border-color": "red.300",
        "placeholder": "Masukkan Harga",
        "height": "62px"
      },
      model: {
        value: item.price,
        callback: function callback($$v) {
          _vm.$set(item, "price", $$v);
        },
        expression: "item.price"
      }
    })], 1)], 1), _c('c-form-control', {
      attrs: {
        "mb": "25px",
        "is-required": "",
        "is-invalid": item.serviceTarget === ''
      }
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "14px",
        "color": "#555555",
        "font-weigh": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Target Layanan ")]), _c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.serviceTarget,
        expression: "item.serviceTarget"
      }, {
        name: "chakra",
        rawName: "v-chakra",
        value: {
          border: '1px solid #C4C4C4',
          padding: '18px',
          width: '100%',
          borderRadius: '6px',
          '&:focus, &:focus-visible': {
            outline: 'unset'
          }
        },
        expression: "{\n            border: '1px solid #C4C4C4',\n            padding: '18px',\n            width: '100%',\n            borderRadius: '6px',\n            '&:focus, &:focus-visible': {\n              outline: 'unset',\n            },\n          }"
      }],
      attrs: {
        "rows": "3",
        "placeholder": "Masukkan Target Layanan"
      },
      domProps: {
        "value": item.serviceTarget
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(item, "serviceTarget", $event.target.value);
        }
      }
    })], 1)], 1);
  }), _c('c-button', {
    attrs: {
      "is-disabled": _vm.disabledAddProductService,
      "variant-color": "primary",
      "variant": "outline",
      "border-radius": "60px",
      "h": "62px",
      "w": "100%",
      "mb": "25px",
      "left-icon": "add"
    },
    on: {
      "click": _vm.addProductService
    }
  }, [_vm._v(" Tambah Program Layanan Lain ")]), _c('c-flex', {
    attrs: {
      "mt": "28px",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "gray",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "mr": "20px",
      "font-size": "18px",
      "color": "#555555"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "w": "50%",
      "h": "62px",
      "font-size": "18px",
      "is-disabled": _vm.isDisabledSubmit || _vm.disabledAddProductService
    },
    on: {
      "click": function click($event) {
        !_vm.id ? _vm.onSubmit() : _vm.isOpen = true;
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 2), _c('c-alert-dialog', {
    attrs: {
      "is-open": _vm.isOpen,
      "least-destructive-ref": _vm.$refs.cancelRef
    }
  }, [_c('c-alert-dialog-overlay', {
    attrs: {
      "z-index": "1"
    }
  }), _c('c-alert-dialog-content', {
    attrs: {
      "text-align": "center",
      "z-index": "2",
      "w": "488px",
      "top": "40px",
      "py": "40px",
      "px": "40px",
      "box-shadow": "4px 4px 50px 5px rgba(0, 0, 0, 0.1)",
      "border-radius": "12px"
    }
  }, [_c('c-image', {
    attrs: {
      "w": "176px",
      "mx": "auto",
      "mb": "49px",
      "src": require('@/assets/icon-confirm-update.svg')
    }
  }), _c('c-alert-dialog-body', {
    attrs: {
      "text-align": "center",
      "mb": "35px",
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "27px"
    },
    domProps: {
      "innerHTML": _vm._s('Apakah anda yakin ingin melakukan <br/> perubahan data?')
    }
  }), _c('c-alert-dialog-footer', {
    attrs: {
      "p": "0"
    }
  }, [_c('c-button', {
    ref: "cancelRef",
    attrs: {
      "w": "50%",
      "h": "auto",
      "py": "17px",
      "border-radius": "60px"
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = false;
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "w": "50%",
      "h": "auto",
      "py": "17px",
      "border-radius": "60px",
      "variant-color": "primary",
      "ml": "3",
      "min-w": "80px"
    },
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }